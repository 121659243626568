import { ref, reactive, computed } from 'vue'
import { defineStore } from 'pinia'
import { darkTheme, lightTheme } from 'naive-ui'
import {lightThemeOverrides,darkThemeOverrides}from '@data/themeOverrides'
export const useConfigStore = defineStore("useConfigStore",()=>{
    const theme = ref(lightTheme)
    const themeOverrides = ref(lightThemeOverrides)
    const switchTheme = () => {
        if (theme.value.name=='light'){
            theme.value = darkTheme
            themeOverrides.value = darkThemeOverrides
        }else {
            theme.value = lightTheme
            themeOverrides.value = lightThemeOverrides
        }
    }
    return {
        theme,
        themeOverrides,
        switchTheme
    }
})