import { ref, reactive, computed } from 'vue'
import { defineStore } from 'pinia'

export const useLoginInfoStore = defineStore(
  'useLoginInfoStore',
  () => {
    const isLogin = ref(false)
    const userInfo = ref()
    const token = ref()
    const touchId = ref()

    const setUserLoginInfo = (info, tokenInfo) => {
      userInfo.value = info
      token.value = tokenInfo
      isLogin.value = tokenInfo.isLogin
    }

    const setUserInfo = (userInfo) => {
      userInfo = userInfo
    }
    const clearUserInfo = () => {
      userInfo = ref()
      isLogin = false
    }
    const setToken = (token) => {
      token = token
    }
    const bindTouchId = computed(() => {
      // return userInfo.value.touchId != null
    })
    // const isLogin = computed(() => )
    return {
      isLogin,
      bindTouchId,
      userInfo,
      token,
      setUserInfo,
      clearUserInfo,
      setToken,
      setUserLoginInfo,
    }
  },
  {
    persist: [
      {
        paths: ['isLogin', 'userInfo', 'token'],
        storage: localStorage,
      },
    ],
  }
)
