import { get, post } from '@config/request.js'
export const getNumCaptcha = () => {
  return get('captcha/number')
    .then((res) => {
      return res
    })
    .catch((error) => {
      return false
    })
}
