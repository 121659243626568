<template>
  <n-config-provider :theme="theme" :theme-overrides="themeOverrides" :locale="zhCN" :date-locale="dateZhCN">
    <!--    <n-theme-editor>-->
    <Header></Header>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in" appear>
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
    <!--    </n-theme-editor>-->
  </n-config-provider>
  <!--    <el-container>-->
  <!--      <el-header class="index-nav" style="background-color: var(&#45;&#45;header-bg-color);height: 50px;">-->
  <!--        <el-menu id="nav-menu" :default-active="0" mode="horizontal" :ellipsis="false" background-color="#fff"-->
  <!--          text-color="#484848" active-text-color="#484848" @select="handleSelect">-->
  <!--          &lt;!&ndash; <div class="flex-grow-1" /> &ndash;&gt;-->
  <!--          <el-menu-item index="0" style="border-bottom: 0;" @click="router.push('/')">-->
  <!--            <img style="width: 40px;border-radius: 8px;" src="./assets/logo.jpg" alt="logo" />-->
  <!--            <div id="logo-text">&nbsp;karax导航</div>-->
  <!--          </el-menu-item>-->
  <!--          <div style="flex-grow: 1;" />-->
  <!--          <el-menu-item index="1" style="margin-right: 10px;">-->
  <!--            <el-switch id="toggleDark" v-model="toggleDarkModel" :active-action-icon="Moon"-->
  <!--              :inactive-action-icon="Sunny" @click="change" @beforeChange="beforeChange">-->
  <!--            </el-switch>-->
  <!--          </el-menu-item>-->
  <!--          <el-menu-item index="2">-->
  <!--            <el-dropdown trigger="click">-->
  <!--              <el-avatar>{{ isLogin }}</el-avatar>-->
  <!--              <template #dropdown>-->
  <!--                <el-dropdown-menu>-->
  <!--                  <el-dropdown-item v-if="!isLogin" @click="loginClick" :key="1">立即登录</el-dropdown-item>-->
  <!--                  <el-dropdown-item v-if="isLogin" :key="2"-->
  <!--                    @click="router.push('/account/home')">个人中心</el-dropdown-item>-->
  <!--                  <el-dropdown-item v-if="isLogin" :key="3">管理页面</el-dropdown-item>-->
  <!--                  <el-dropdown-item v-if="isLogin" :key="4" divided @click="doLogout">退出登录</el-dropdown-item>-->
  <!--                </el-dropdown-menu>-->
  <!--              </template>-->
  <!--            </el-dropdown>-->
  <!--          </el-menu-item>-->
  <!--        </el-menu>-->
  <!--      </el-header>-->
  <!--      <el-main class="index-main">-->
  <!--        <router-view />-->
  <!--        &lt;!&ndash; </el-scrollbar> &ndash;&gt;-->
  <!--      </el-main>-->
  <!--      <div id="beian">-->
  <!--        <BeiAn ipc="浙ICP备19047323号" num="2" />-->
  <!--      </div>-->
  <!--    </el-container>-->
  <!--    <el-dialog v-model="loginDialogVisible" title="登录" width="500" align-center :close-on-click-modal="false"-->
  <!--      style="width: 30vw;max-width: 400px;min-width: 320px;">-->
  <!--      <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="60px" status-icon-->
  <!--        label-position="left">-->
  <!--        <el-form-item label-width="0px" prop="username" style="display: flex; align-items: center;">-->
  <!--          <el-input v-model="loginForm.username" placeholder="请输入账号" size="large" />-->
  <!--        </el-form-item>-->
  <!--        <el-form-item label-width="0px" prop="password" style="display: flex; align-items: center;">-->
  <!--          <el-input type="password" v-model="loginForm.password" placeholder="请输入密码" size="large" />-->
  <!--        </el-form-item>-->
  <!--        <el-form-item label-width="0px" prop="code" style="display: flex; align-items: center;">-->
  <!--          <el-input type="text" v-model="loginForm.code" placeholder="请输入验证码" show-word-limit maxlength="4"-->
  <!--            size="large">-->
  <!--            <template #append>-->
  <!--              <img style="width: 100px;height: 40px; border-radius: 0px 2px 2px 0px;cursor: pointer;"-->
  <!--                @click="getCaptcha" :src="captchaBase64" />-->
  <!--            </template>-->
  <!--          </el-input>-->
  <!--        </el-form-item>-->
  <!--      </el-form>-->
  <!--      <template #footer>-->
  <!--        <div class="dialog-footer">-->
  <!--          <el-button type="success" style="position: absolute; left: 15px;" @click="doFingerprint">指纹认证</el-button>-->
  <!--          <el-button @click="resetForm(loginFormRef)">取消</el-button>-->
  <!--          <el-button type="primary" @click="handleLogin(loginFormRef)">-->
  <!--            登录-->
  <!--          </el-button>-->
  <!--        </div>-->
  <!--      </template>-->
  <!--    </el-dialog>-->
</template>

<script setup>
import Header from "@views/layout/Header.vue";
import { dateZhCN, zhCN } from 'naive-ui'
import { onMounted, ref, reactive, toRefs, computed, watch } from 'vue'
import { ElMessage } from 'element-plus'
import { useRoute, useRouter } from "vue-router"
import { storeToRefs } from 'pinia'
import { now, useDark, useToggle } from '@vueuse/core'
import { useLoginInfoStore } from '@/store/useLoginInfoStore.js'
import { useWebsocketStore } from '@/store/useWebSocketStore.js'
import { useConfigStore } from "@/store/useConfigStore";
import { getNumCaptcha } from '@api/system/captcha.js'
import { login, loginByTouchId, logout, checkLogin } from '@api/system/login.js'
import { encrypt } from "@utils/jsencrypt.js"
import { touchIDOptions } from "@/data/options.js"
import { arrayBufferToString, arrayBufferToBase64, base64ToArrayBuffer } from '@/utils/base64.js'
const router = useRouter()
import { NThemeEditor } from 'naive-ui'
let query = {}
watch(() =>
  router.currentRoute.value.path,
  (toPath) => {
    query = router.currentRoute.value.query;
    if (toPath == '/sso/login' && query.back) {
      loginClick()
    }
  }, { immediate: true, deep: true })

const { theme, themeOverrides } = storeToRefs(useConfigStore())
console.log(themeOverrides.value)
const loginInfoStore = useLoginInfoStore()

const { isLogin, userInfo, token } = storeToRefs(loginInfoStore)

const webSocketStore = useWebsocketStore()
const { cancelWebSocketConnect } = storeToRefs(webSocketStore)

onMounted(() => {
  checkLogin().then(res => {
    if (!res.data) {
      loginInfoStore.$patch({
        isLogin: false,
        token: {}
      })
    }
  })
  if (isLogin.value && !cancelWebSocketConnect.value) {
    webSocketStore.token = token.value.tokenValue
    webSocketStore.connectWebSocket(token.value.tokenValue)
  }
});

const isDark = useDark({
  storageKey: "theme",
  selector: "html",
  attribute: "class",
  valueDark: "dark",
  valueLight: "light",
});
const toggleDarkModel = ref(isDark.value)
const toggleDark = useToggle(isDark)
const loginDialogVisible = ref(false)
const loginForm = reactive({
  username: userInfo.userName,
  password: '',
  uuid: '',
  code: '',
  codeType: ''
})
const loginFormRef = ref()
const loginFormRules = reactive({
  username: [
    { required: true, message: '请输入账号', trigger: 'blur' },
  ],
  password: [
    {
      required: true,
      message: '请输入密码',
      trigger: 'blur',
    },
  ],
  code: [
    {
      required: true,
      message: '请输入验证码',
      trigger: 'blur',
    },
    { min: 4, max: 4, message: '请输入4位验证码', trigger: 'blur' },
  ]
})

const getCaptcha = () => {
  getNumCaptcha().then(res => {
    captchaBase64.value = res.data.data
    loginForm.codeType = "NUM"
    loginForm.uuid = res.data.uuid
  })
}

const captchaBase64 = ref()
const loginClick = () => {
  loginDialogVisible.value = true
  getCaptcha()
}

const handleLogin = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      let loginBody = JSON.parse(JSON.stringify(loginForm))
      loginBody.password = encrypt(loginForm.password)
      login(loginBody).then(res => {
        if (res.code == 200) {
          loginDialogVisible.value = false
          webSocketStore.cancelWebSocketConnect = false
          loginInfoStore.setUserLoginInfo(res.data.userInfo, res.data.tokenInfo)
          refresh()
        } else {
          getCaptcha()
        }
      })
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  loginDialogVisible.value = false
  formEl.resetFields()
}

const touchIDLoginOptions = {
  publicKey: {
    challenge: new ArrayBuffer(0), // 凭证(ArrayBuffer)
    allowCredentials: [
      {
        type: "public-key",
        id: new ArrayBuffer(0), // 注册时的rawId(ArrayBuffer)
        transports: ["internal"] // 使用内置指纹
      }
    ]
  }
};

const doFingerprint = async () => {
  if (!userInfo.value) {
    ElMessage.warning({ message: '请先在设备上登录过账号再使用本功能！' })
    return;
  }

  if (!userInfo.value.touchId) {
    ElMessage.warning({ message: '请先绑定touchId再使用本功能！' })
    return;
  }

  try {
    const hasTouchID = await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
    if (hasTouchID) {
      // 更新登录凭证
      touchIDLoginOptions.publicKey.challenge = new TextEncoder().encode(userInfo.value.userId);
      // 更新touchID
      console.log(userInfo.value.touchId)
      touchIDLoginOptions.publicKey.allowCredentials[0].id = base64ToArrayBuffer(userInfo.value.touchId);

      // 开始校验指纹
      await navigator.credentials.get(touchIDLoginOptions);
      // 调用指纹登录接口
      loginByTouchId({
        certificate: userInfo.value.userId,
        touchId: userInfo.value.touchId
      }).then(res => {
        if (res.code == 200) {
          loginDialogVisible.value = false
          loginInfoStore.setUserLoginInfo(res.data.userInfo, res.data.tokenInfo)
          refresh()
        }
      })
    } else {
      ElMessage.warning({ message: '您的设备不支持touchId' })
    }
  } catch (e) {
    if (e instanceof ReferenceError) {
      ElMessage.error({ message: '当前网络环境不安全，已禁用touchId' })
    } else if (e instanceof DOMException) {
      ElMessage.warning({ message: '已取消授权' })
    }
  }
}


const doLogout = () => {
  logout().then(res => {
    loginInfoStore.$patch({
      isLogin: false,
      token: {}
    })
    router.push("/")
  })
}

const refresh = () => {
  if (query.back) {
    window.location.replace(query.back);
  } else {
    location.reload()
  }
}


const change = (event) => {
  const x = event.clientX;
  const y = event.clientY;
  const endRadius = Math.hypot(
    Math.max(x, innerWidth - x),
    Math.max(y, innerHeight - y)
  );

  // 兼容性处理
  if (!document.startViewTransition) {
    toggleDark();
    return;
  }
  const transition = document.startViewTransition(async () => {
    toggleDark();
  });

  transition.ready.then(() => {
    const clipPath = [
      `circle(0px at ${x}px ${y}px)`,
      `circle(${endRadius}px at ${x}px ${y}px)`,
    ];
    document.documentElement.animate(
      {
        clipPath: isDark.value ? [...clipPath].reverse() : clipPath,
      },
      {
        duration: 400,
        easing: "ease-in",
        pseudoElement: isDark.value
          ? "::view-transition-old(root)"
          : "::view-transition-new(root)",
      }
    );
  });
};

const patchUserInfo = (data) => {
  loginInfoStore.$patch({
    userInfo: data.userInfo,
    isLogin: data.tokenInfo.isLogin,
    token: {
      tokenName: data.tokenInfo.tokenName,
      tokenValue: data.tokenInfo.tokenValue
    }
  })
}

const activeIndex = ref('1')
const handleSelect = (key, keyPath) => {
  console.log(key, keyPath)
}

</script>

<style>
#app {
  font-family: Arial, Helvetica, sans-serif;
}

#nprogress .bar {
  background: var(--primary-active-color);
}

#nav {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--header-bg-color);
}

#nav-menu {
  width: 100%;
  height: 50px;
  --el-menu-bg-color: var(--header-bg-color) !important;
  --el-menu-hover-bg-color: var(--header-bg-color) !important;
  --el-menu-text-color: var(--header-font-color) !important;
}

#logo-text {
  font-size: 20px;
  color: var(--header-font-color);
  font-weight: 400;
}

.el-menu--horizontal>.el-menu-item {
  border-bottom: 0 !important;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 0 !important;
}


.el-menu-item [class^=el-icon] {
  margin-right: 0px !important;
  font-size: 14px !important;
}

.el-input .el-input-group__append {
  padding: 2px 2px 2px 0px !important;
}

.el-dialog__body {
  padding: 0px 0px 0px 0px !important;
}

.index-main {
  background-color: var(--body-bg-color);
  padding: 0px 0px !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
