import axios from 'axios'
import { ElMessage } from 'element-plus'
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        ElMessage.error({ message: '网络异常' })
        // reject(err)
      })
  })
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        ElMessage.error({ message: '网络异常' })
        // reject(err)
      })
  })
}
