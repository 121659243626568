import { createRouter, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress' // 导入 nprogress
import 'nprogress/nprogress.css'
const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index/IndexPage.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about/about.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue'),
  },
  {
    path: '/sso/login/:back',
    name: 'sso',
    component: () => import('@/views/index/IndexPagebak.vue'),
  },
  {
    path: '/hxx',
    name: 'hxx',
    component: () => import('@views/inside/HxxView.vue'),
  },
  {
    path: '/canvas',
    name: 'canvas',
    component: () => import('@/views/inside/Canvas.vue'),
  },
  {
    path: '/HearthStone',
    name: 'HearthStone',
    component: () => import('@/views/inside/HearthStone.vue'),
  },
  {
    path: '/game/2048',
    name: '2048',
    component: () => import('@views/game/2048.vue'),
  },
  {
    path: '/game/2048test',
    name: '2048test',
    component: () => import('@views/game/2048copy1.vue'),
  },
  {
    path: '/game/minesweeper',
    name: 'minesweeper',
    component: () => import('@views/game/minesweeper.vue'),
  },
  {
    path: '/account/home',
    component: () => import('@views/account/Home.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

NProgress.configure({ showSpinner: false })

router.beforeEach((to, from, next) => {
  NProgress.start()
  document.title = 'karax导航'
  document
    .querySelector('link[rel="icon"]')
    .setAttribute('href', '../assets/logo.jpg')
  // 判断有没有登录
  // if (!localStorage.getItem('token')) {
  //   if (to.name == 'login') {
  //     next()
  //   } else {
  //     router.push('login')
  //   }
  // } else {
  //   next()
  // }
  next()
  NProgress.done()
})
export default router
