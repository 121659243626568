import { get, post } from '@config/request.js'
export const login = (loginForm) => {
  return post('sys/account/login', loginForm)
    .then((res) => {
      return res
    })
    .catch((error) => {
      return false
    })
}

export const loginByTouchId = (touchIdLoginForm) => {
  return post('sys/account/loginByTouchId', touchIdLoginForm)
    .then((res) => {
      return res
    })
    .catch((error) => {
      return false
    })
}

export const logout = () => {
  return get('sys/account/logout')
    .then((res) => {
      return res
    })
    .catch((error) => {
      return false
    })
}

export const checkLogin = () => {
  return get('sys/account/checkLogin')
    .then((res) => {
      return res
    })
    .catch((error) => {
      return false
    })
}

export const modifyInfo = (userInfo) => {
  return post('sys/account/modifyInfo', userInfo)
    .then((res) => {
      return res
    })
    .catch((error) => {
      return false
    })
}
