<template>
  <n-layout-header bordered>
    <n-grid :cols="12" item-responsive responsive="screen" style="height: 50px">
      <n-gi span="0 m:2" class="header-side align-center">
        <img class="pointer" style="width: 40px;border-radius: 8px;" src="../../assets/logo.jpg" alt="logo" @click="router.push('/')"/>
        <span class="pointer" id="logo-text" @click="router.push('/')">&nbsp;karax导航</span>
      </n-gi>
      <n-gi span="12 m:4" class="align-center">
        <n-menu
            class="header-menu"
            v-model:value="activeKey"
            mode="horizontal"
            :options="menuOptions"
        />
      </n-gi>
      <n-gi span="0 m:2" class="align-center">
        <n-input placeholder="搜索">
          <template #prefix>
            <n-icon :component="SearchIcon" />
          </template>
        </n-input>
      </n-gi>
      <n-gi span="0 m:3" class="header-side align-center">
        <n-flex justify="end" align="center" style="width: 100%;height: 100%">
          <n-button quaternary @click="useConfigStore().switchTheme()">
            <template #icon>
              <n-icon v-if="useConfigStore().theme.name=='light'"><MoonIcon/></n-icon>
              <n-icon v-else><SunnyIcon /></n-icon>
            </template>
          </n-button>
          <div id="avatar" class="flex-center pointer">
            <n-dropdown trigger="click"  :options="avatarDropDownOptions" @select="handleAvatarDropDownSelect">
              <n-avatar
                  round
                  bordered="true"
                  size="medium"
                  src="https://07akioni.oss-cn-beijing.aliyuncs.com/07akioni.jpeg"
              />
            </n-dropdown>
          </div>
        </n-flex>
      </n-gi>
    </n-grid>
  </n-layout-header>
</template>


<script setup>
import {computed, defineComponent, h, reactive, ref, watch} from "vue";
import {RouterLink} from "vue-router";
import {NIcon, NInput} from "naive-ui";
import {storeToRefs} from 'pinia'
import {useConfigStore} from "@/store/useConfigStore";
import {useLoginInfoStore} from "@/store/useLoginInfoStore";
import {
  HomeOutline as HomeIcon,
  BrowsersOutline as BrowserIcon,
  SunnyOutline as SunnyIcon,
  MoonOutline as MoonIcon,
  SearchOutline as SearchIcon,
} from "@vicons/ionicons5";
import router from "@/router";

const {theme} = storeToRefs(useConfigStore())
const {isLogin} = storeToRefs(useLoginInfoStore())

const sideStyle = reactive({
  minWidth: '150px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})
const activeKey = ref()

watch(() => router.currentRoute.value.name,
    (name) => {
      activeKey.value = name
    },
    {immediate:true,deep: true}
)

const avatarDropDownOptions = computed(()=>{
  let options
  if (isLogin.value==false){
    options =[
      {
        label: '立即登录',
        key: 'login'
      }
    ]
  }else {
    options =[
      {
        label: '个人中心',
        key: 'account'
      }
    ]
  }
  return options
})

const handleAvatarDropDownSelect = (key)=>{
  switch (key){
    case 'login':
      router.push("/login")
      break
    default:
  }
}



const emitEvents = defineEmits(['son-click'])

function renderIcon(icon) {
  return () => h(NIcon, null, {default: () => h(icon)});
}

const menuOptions = [
  {
    label: () => h(
        RouterLink,
        {
          to: {
            name: "index",
            params: {
              lang: "zh-CN"
            }
          }
        },
        {default: () => "首页"}
    ),
    key: "index",
    icon: renderIcon(HomeIcon),
  },
  {
    label: () => h(
        RouterLink,
        {
          to: {
            name: "about",
            params: {
              lang: "zh-CN"
            }
          }
        },
        {default: () => "关于"}
    ),
    key: "about",
    icon: renderIcon(BrowserIcon),
  },
];
</script>

<style>
#avatar {
  overflow: visible;
  transition: transform 0.5s ease-in-out;
}

#avatar:hover {
  transform: translate(0,4%) scale(1.1);
}
#logo{
  cursor: pointer;
}
#logo-text {
  font-size: 20px;
  color: var(--header-font-color);
  font-weight: 400;
}

#n-layout {
  --n-color: rgb(16, 16, 20) !important;
}
</style>
