import { defineStore } from 'pinia'
import { ref } from 'vue'
import { ElMessage } from 'element-plus'
import { ElMessageBox } from 'element-plus'

export const useWebsocketStore = defineStore(
  'useWebsocketStore',
  () => {
    //state
    const msg = ref('') //发送的消息
    var socket = ref()
    const token = ref('') //传递的参数
    const state = ref(0) //链接标志
    const cancelWebSocketConnect = ref(false)

    const lockReconnect = ref(false)
    const timeout = ref(2000)
    const timeoutDown = ref()
    const serverTimeoutObj = ref() //心跳倒计时
    var heartInterval = ref() //断开 重连倒计时
    //action
    // Websoket连接成功事件
    const websocketonopen = (res) => {
      state.value = WebSocket.OPEN
      ElMessage.success({ message: '与服务器连接成功', duration: 800 })
      // timeoutnum && clearTimeout(timeoutnum)
      // heartInterval = setInterval(() => {
      //   sendMsg(JSON.stringify({ msgType: 'HEART', msg: 'PING' }))
      // }, 10000)
    }
    // Websoket接收消息事件
    const websocketonmessage = (res) => {
      console.log('数据', res.data)
      msg.value = res.data
    }
    // Websoket连接错误事件
    const websocketonerror = (res) => {
      state.value = WebSocket.CONNECTING
    }
    // Websoket断开事件
    const websocketclose = (res) => {
      ElMessageBox.confirm('与服务器连接失败，是否要重连？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'error',
      })
        .then(() => {
          connectWebSocket(token)
        })
        .catch(() => {
          cancelWebSocketConnect.value = true
          state.value = WebSocket.CONNECTING
        })
    }

    //创建链接
    const connectWebSocket = (token) => {
      const wsurl = `ws://localhost:8000/api/ws?token=${token}`
      socket.value = new WebSocket(wsurl)
      socket.value.onopen = websocketonopen
      socket.value.onmessage = websocketonmessage
      socket.value.onerror = websocketonerror
      socket.value.onclose = websocketclose
    }
    const sendMsg = (msg) => {
      if (state.value == WebSocket.OPEN && socket.value != null) {
        socket.value.send(JSON.stringify(msg))
      } else {
        ElMessage.error({ message: '与服务器断开连接', duration: 800 })
      }
    }
    //关闭链接
    const closetWebSocket = () => {
      websocketclose
      // 销毁 websocket 实例对象
      count.value = WebSocket.CONNECTING
    }

    return {
      msg,
      sendMsg,
      socket,
      cancelWebSocketConnect,
      connectWebSocket,
      closetWebSocket,
    }
  },
  {
    persist: [
      {
        paths: ['cancelWebSocketConnect'],
        storage: localStorage,
      },
    ],
  }
)
