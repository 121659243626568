const lightThemeOverrides = {
    "Layout": {
        "colorEmbedded": "rgba(242, 242, 242, 1)"
    }
    // ...
}

const darkThemeOverrides = {

}

export {lightThemeOverrides,darkThemeOverrides}