const touchIDOptions = {
  publicKey: {
    rp: { name: 'index-page' }, // 网站信息
    user: {
      name: '', // 用户名
      id: new ArrayBuffer(0), // 用户id(ArrayBuffer)
      displayName: '', // 用户名
    },
    pubKeyCredParams: [
      { type: 'public-key', alg: -7 },
      {
        type: 'public-key',
        alg: -35,
      },
      { type: 'public-key', alg: -36 },
      { type: 'public-key', alg: -257 },
      {
        type: 'public-key',
        alg: -258,
      },
      { type: 'public-key', alg: -259 },
      { type: 'public-key', alg: -37 },
      {
        type: 'public-key',
        alg: -38,
      },
      { type: 'public-key', alg: -39 },
      { type: 'public-key', alg: -8 },
    ], // 接受的加密算法
    challenge: new ArrayBuffer(0), // 凭证(ArrayBuffer)
    timeout: 60000, // 授权超时时间
    authenticatorSelection: {
      authenticatorAttachment: 'platform',
    }, // 只接受指纹登录或windows hello登录
  },
}

export { touchIDOptions }
