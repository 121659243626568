import axios from 'axios'
import { ElMessage } from 'element-plus'
// 根据环境设定请求后端 url 地址
//axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.baseURL = 'https://www.huxixi.cn/api'
//axios.defaults.baseURL = 'http://localhost:8000/api'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    const loginInfo = JSON.parse(localStorage.getItem('useLoginInfoStore'))
    config.headers = {
      'Content-Type': 'application/json',
      'unified-token': loginInfo ? loginInfo.token.tokenValue : '',
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    response = response.data
    if (response.code == 401) {
      // window.location.replace("/");
    }
    if (response.code !== 200) {
      ElMessage.error({ message: response.msg })
    }
    return response
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)
